<template>
	<section class="mother">
		<template v-if="$root.rwd < 2">
			<lottie :options="{ path: '/lottie/Miscanthus01/data.json', autoplay: true, loop: true }" />
		</template>
		<div class="max-width-1000">
			<br>
			<br>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item to="/about">關於金枝</el-breadcrumb-item>
				<el-breadcrumb-item>金枝的阿母</el-breadcrumb-item>
			</el-breadcrumb>
			<br>
			<quote-title>
				<div class="col" align-x="center" gutter="10">
					<p><b>金枝的阿母</b></p>
					<h1>謝月霞</h1>
				</div>
			</quote-title>
			<br>
			<template v-if="$root.rwd < 3">
				<template v-for="({url, name}, i) in _about.banners">
					<img :src="url" width="100%" :alt="name" :key="i">
				</template>
			</template>
			<template v-else>
				<template v-for="({url, name}, i) in _about.banners_mobile">
					<img :src="url" width="100%" :alt="name" :key="i">
				</template>
			</template>
			<br>
			<article v-html="_about.article" />
		</div>
		<br>
		<br>
		<br>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex"
export default {
	computed: {
		...mapState("about", ["_about"]),
	},
	methods: {
		...mapActions("about", ["_getData"]),
	},
	async created() {
		await this._getData("mother");
	}
}
</script>

<style lang="scss" scoped>
.mother {
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../../../assets/background/brush1.png"),
		url("../../../assets/background/dot1.png");
	background-position: center 20%, center 20%;
	padding: 0;

	@media screen and (min-width: 768px) {
		padding: 0 5%;
	}

	.lottie {
		position: absolute;
		width: 25%;
		max-width: 220px;
		top: 8%;
		right: 20px;
	}

	.el-breadcrumb {
		@media screen and (max-width: 767px) {
			padding-left: 5%;
		}
	}
}
</style>